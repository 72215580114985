import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Característic bolet amb capell de color verd oliva, viscós en temps humit i de 3 a 9 cm de diàmetre. Primer el capell és molt convex per a passar a més obert i pla. Davall aquest les làmines, nombroses i atapeïdes, són liles i tenyides després de color ocre per les espores. El peu és curt i engruixut cap a la base, de 3 a 4 cm x 1 a 2 cm de color groc tirant a verd. Aquest presenta abundants restes de cortina també tenyida per la caiguda de les espores. Les espores són de color rovell a rogenc en massa, en forma d’ametla, molt berrugoses, de 10-11 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      